body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Custom scrollbar */
/* width */
::-webkit-scrollbar {
	width: 10px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
	background: var(--main-bg-color);
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
	background: var(--primary-button-color);
    border-radius: 50px;
	border: 2px solid var(--main-bg-color);
    width: 10px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
  background: var(--primary-button-highlight-color);
  cursor: pointer;
  }
