.comments-section .comment, .add-comment {
    background-color: var(--card-color);
    color: var(--main-font-color);
    border-radius: 5px;
    padding: 30px 50px;
    margin-bottom: 10px;
}

.comments-section .comment p {
    text-align: justify;
}

.comments-section .comment img {
    width: 50px;
    height: 50px;
    border-radius: 100%;
    margin-right: 20px;
}

.comments-section .comment header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.comment-body {
    word-break: break-word;
}

.add-comment {
    display: flex;
    flex-direction: column;
}

.add-comment textarea {
    border-radius: 5px;
    margin-bottom: 10px;
    resize: none;
    min-height: 200px;
    padding: 2%;
    color: var(--main-font-color);
    border: none;
    background-color: var(--input-color);
}

.accept-reject-buttons {
    display: flex;
    justify-content: space-between;
}

.accept-reject-buttons button {
    flex-grow: 1;
}

.accept-reject-buttons button:disabled {
    cursor: not-allowed;
    background-color: var(--disabled-button-color) !important;
}


.accept-reject-buttons button:first-child {
    margin-right: 10px;
}

.approved, .rejected {
    padding: 5px 10px;
    width: fit-content;
    border-radius: 50px;
}

.approved {
    background-color: #20AD49;
}

.rejected {
    background-color: rgb(236, 15, 15);
}