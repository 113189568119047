ol {
    padding: 0;
    padding-bottom: 40px;
    width: 60%;
    overflow-y: auto;
    overflow-x: hidden;
    margin-top: -42px;
}

ol h4 {
    text-align: left;
    margin-top: 0;
}

ol .task {
    animation: 0.5s ease-out 0s 1 slideInFromLeft;
}

ol.legacy {
  width: 40%;
}

@keyframes slideInFromLeft {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }