.document-viewer header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.document-viewer h4 {
    text-align: left;
    margin: 0;
}

.document-viewer h4.comments-header {
    margin-top: 30px;
}

.document {
    margin-bottom: 20px;
    border-radius: 5px;
    overflow-x: auto;
}

.add-comment-main {
    width: 100%;
    margin-bottom: 10px;
}