.vertical-form {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  margin: 0 20vw;
}

.vertical-form input,
.vertical-form select {
  width: 100%;
  margin-bottom: 10px;
}

.vertical-form button {
  margin-top: 10px;
  width: 100%;
}

.vertical-form .group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  white-space: nowrap;
  flex-wrap: wrap;
}

.vertical-form .group label {
  width: 10vw;
  text-align: left;
}
