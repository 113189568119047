table {
    flex-grow: 1;
    text-align: left;
}

table th {
    min-width: 150px;
}

table td {
    padding-right: 20px;
    max-width: 20vw;
}

table textarea {
    width: 90%;
    height: 90%;
    resize: none;
}

td {
    word-wrap: break-word;
}