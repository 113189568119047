.prob-viewer {
    background-color: var(--card-color);
    padding: 30px 50px;
}

.probationary-category {
    display: flex;
    flex-wrap: wrap;
    height: 100%;
}

.probationary-category .category, .probationary-category .input {
    background-color: var(--card-color);
    border-radius: 5px;
    margin-bottom: 20px;
}

.probationary-category .category {
    width: 37%;
    margin-right: 20px;
    padding: 20px;
    text-align: left;
    height: fit-content;
}

.probationary-category .category .help {
    display: flex;
    justify-content: space-between;
}

.probationary-category .category .help i {
    cursor: pointer;
}

.probationary-category .input {
    padding: 20px;
    display: flex;
    flex-direction: column;
    width: calc(100% - 100px - 37%);
}

.probationary-category input p {
    min-width: 100%;
    width: 0;
}

.colleagueScoreBox {
    width: calc(100% - 40px);
    color: var(--main-font-color);
    cursor: default;
    background-color: var(--disabled-button-color);
}

.probationary-category .input h4 select{
    margin-left: 20px;
}

.probationary-category .input textarea {
    width: 96%;
    min-height: 200px;
    flex-grow: 1;
    resize: none;
    border-radius: 5px;
    padding: 2%;
    background-color: var(--input-color);
    color: var(--main-font-color);
    border: none;
}

.slide-buttons button:first-child {
    margin-right: 20px;
}

.category select {
    width: 100%;
}

@media(max-width: 1200px) {
    .probationary-category .category {
        width: calc(100% - 30px);
        margin-right: 0;
        padding: 15px;
    }

    .probationary-category .category p {
        margin-bottom: 50px;
    }
    
    .probationary-category .input {
        padding: 15px;
        display: flex;
        flex-direction: column;
        width: calc(100% - 30px);
    }

    .slide-buttons {
        flex-wrap: wrap;
    }

    .slide-buttons button {
        width: 100%;
        margin-right: 0;
    }

    .slide-buttons button:first-child {
        margin-right: 0;
        margin-bottom: 20px;
    }
}