body {
  background: var(--main-bg-color);
  color: var(--main-font-color);
  max-height: 100vh;
}

.gdpr-popup {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20vw 5vw;
  height: 100%;
}

.gdpr-popup a {
  color: var(--main-font-color);
}

.main-section {
  padding: 5vh 5vh 0 5vh;
  height: 95vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.main-section nav {
  display: flex;
  justify-content: flex-start;
}

nav .nav-right {
  display: flex;
  align-items: center;
}

nav .nav-right button {
  margin-right: 20px;
}

.content {
  /*height: calc(100% - 100vh - 21px);*/
  padding: 40px 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.heading {
  font-size: 45px;
  text-align: left;
  margin-top: 0;
  font-weight: 400;
}

.due-date h2 {
  margin: 0;
}

.main-section nav {
  display: flex;
  justify-content: space-between;
}

.left-align-text {
  text-align: left;
}

.go-back i {
  margin-right: 10px;
}

.italic {
  font-style: italic;
}

footer {
  color: var(--main-font-color);
  padding: 20px;
  font-size: 14px;
}

.logos {
  height: 60px;
}

@media (max-width: 1200px) {
  .main-section {
    padding: 0;
    height: 100vh;
  }

  .content {
    overflow-y: auto;
    padding: 20px;
  }
}

@page {
  margin: 20mm;
}

.print-heading, .tr-title {
  display: none;
}

@media print {
  * {
    background-color: white !important;
    color: black;
  }

  .prob-viewer {
    padding: 0 !important;
  }

  .print-heading, .tr-title {
    display: block;
  }

  body {
    width: 100vw;
  }

  table { 
    width: 100%;
  }

  table th {
    /*min-width: 0px !important;*/
    display: none;
  }

  tr {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  table td {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 100%;
    max-width: none !important;
  }

  td p {
    border: 1px solid black;
    min-height: 200px;
    width: calc(100% - 2px);
    box-sizing: border-box;
    padding: 10px;
  }

  td p.guidelines {
    min-height: auto;
    border: 0;
    padding: 0;
  }

  td p.print-score {
    min-height: 40px;
  }

  .td-title, .tr-title {
    width: 100%;
    text-align: center !important;
    font-size: 2em;
  }

  .td-title {
    font-size: 1.5em;
    text-align: center !important;
  }

  table {
    border-spacing: 0;
  }

  header {
    margin: 30px 50px;
  }

  article.comment {
    border: 1px solid black;
  }

  .obj-doc .card header {
    margin: 0;
  }

  .obj-doc .card h4 {
    margin: 0;
  }

  main.document + header {
    margin: 20px;
  }

  .comments-section p {
    margin: 20px;
    text-align: left;
  }

  .comments-section .comment {
    padding: 20px !important;
    margin: 20px;
  }

  .comments-section .comment header {
    margin: 0;
  }
}
