.objective {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--card-color);
    border-radius: 5px;
    padding: 0 20px 0 10px;
    margin-bottom: 10px;
    cursor: pointer;
    color: var(--main-font-color);
    text-decoration: none;
}

.objective .icon {
    width: 50px;
    height: 50px;
    border-radius: 100%;
    margin: 25px 40px;
    background: var(--objectives-color);
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--objectives-contrast-color);
    font-size: 20px;
}

.objective .commented.icon {
    background-color: #00FFA0;
    color: black;
}

.objective .needs-comment.icon {
    background-color: #FFDD00;
    color: black;
}

.objective p, .objective h2 {
    margin: 0
}

.obj-left {
    display: flex;
    align-items: center;
}

p.description {
    flex-grow: 1;
    text-align: left;
}

.due-date {
    min-width: 80px;
}

.current-objectives {
    width: 50%;
    margin-top: -42px;
}

.current-objectives h4 {
    margin-top: 0;
}

@media(max-width: 1200px) {
    .objective {
        flex-direction: column;
    }

    .objective p {
        text-align: justify;
    }

    .due-date {
        margin-bottom: 20px;
    }

    .obj-left {
        flex-direction: column;
    }
}