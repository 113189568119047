.card {
    background-color: var(--card-color);
    margin-bottom: 10px;
    padding: 20px;
    border-radius: 5px;
}

.card .obj-body section {
    width: 50%;
    margin-bottom: 20px;
}

.card .obj-body section p {
    text-align: left;
}

.obj-body {
    display: flex;
    flex-wrap: wrap;
    background-color: transparent;
}

.obj-doc .card header h4 {
    font-size: 25px;
}

.obj-doc .card header p {
    margin: 0;
}

.obj-doc .card header {
    margin-bottom: 20px;
    padding-bottom:20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(128, 128, 128, 0.479);
}

@media(max-width: 1200px) {
    .card .obj-body section {
        width: 100%;
    }

    .obj-doc .card header {
        flex-direction: column;
    }

    .obj-doc .card header .due-date {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: space-between;
        margin: 0;
    }

    .obj-doc .card header .due-date h2, .obj-doc .card header .due-date p {
        font-size: 20px;
        font-weight: normal;
        margin: 0 10px 0 10px;
    }
}