:root {
    --main-bg-color: #0C0C0C;
    --card-color: #191919;
    --main-font-color: white;
    --invert-font-color: black;
    --disabled-button-color: #1f1f1f;
    --primary-button-color: #B7B7B7;
    --primary-button-highlight-color: #dddddd;
    --secondary-button-color: #E9D0B1;
    --secondary-button-highlight-color: #e2dad1;
    --input-color: #2f2f2f;
    --accept-button: #bcf7cb;
    --reject-button: #ffa2a4;
    --error-color: red;
    --cancel-color: #b51515;
    --late-color:#b91717;
    --good-color: #18ad39;

    /* Appraisal colors */
    --objectives-color: #004EFF;
    --objectives-contrast-color: white;
    --year-end-color: #F7BCBD;
    --year-end-contrast-color: black;
    --probationary-color: #A89279;
    --probationary-contrast-color: black;
}