@keyframes spin {
    0% { transform: rotate(0) }
    100% { transform: rotate(360deg) }
  }

  .loading-spinner {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
  }
  
  .loading-spinner .parent {
    width: 50px;
    height: 50px;
    border: 5px solid transparent;
    border-top-color: var(--main-font-color);
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    animation: spin 1s linear infinite;
  }

  .loading-spinner .child {
    width: 30px;
    height: 30px;
    border: 5px solid transparent;
    border-top-color:  var(--main-font-color);;
    border-radius: 100%;
    animation: spin 0.6s linear infinite reverse;
  }