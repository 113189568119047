.task {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--card-color);
    border-left: 10px solid;
    border-radius: 5px;
    padding: 0 20px 0 0;
    margin-bottom: 25px;
    cursor: pointer;
    color: var(--main-font-color);
    text-decoration: none;
}

.task.legacy {
    height: 60px;
}

.task.legacy .due-date h2 {
    font-size: 20px;
}

.task img {
    width: 50px;
    height: 50px;
    border-radius: 100%;
    margin: 25px 40px;
}

.task p {
    margin: 0
}

.task .task-right  button, .task .task-right  .button-style {
    display: none;

}

.task:hover .task-right  button, .task:hover .task-right  .button-style {
    display: flex;
}

.task-left {
    display: flex;
    align-items: center;
}

.task-left.legacy {
    margin-left: 10px;
}

.task-right {
    display: flex;
    align-items: center;
}

.task-right button, .task-right .button-style {
    margin-right: 40px;
    padding: 5px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.task-right button .far, .task-right .button-style .far, .task-right button .fas, .task-right .button-style .fas {
    height: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

p.description {
    flex-grow: 1;
    text-align: left;
    display: flex;
    align-items: center;
}

p.description .completed-tag {
    background: var(--good-color);
    padding: 5px 20px;
    border-radius: 50px;
    width: fit-content;
    margin-right: 20px;
}

p.last-updated {
    font-size: 10px;
    color: #AAAAAA;
    margin-top: 5px;
    text-align: left;
}

.due-date {
    min-width: 80px;
}

.task-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.task.late {
    background-color: var(--late-color);
}

.task.late, .task.late p.last-updated {
    color: white;
}

@media(max-width: 1200px) {
    .task {
        flex-direction: column;
    }

    .task p {
        text-align: justify;
    }

    .due-date {
        margin-bottom: 20px;
    }

    .task-left {
        flex-direction: column;
    }
}