@media(max-width: 1200px) {
    .desktop {
        display: none;
    }
}

@media(min-width: 1201px) {
    .mobile {
        display: none;
    }
}