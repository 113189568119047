.objectives-editor input, .objectives-editor textarea {
    background-color: var(--input-color);
    color: var(--main-font-color);
}

.objectives-editor title {
    display: flex;
    background: var(--card-color);
    border-radius: 5px;
    padding: 20px;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.objectives-editor title h4 {
    margin: 0;
}

.objectives-editor title input {
    flex-grow: 1;
    margin-left: 20px;
}

.objectives-editor article {
    display: flex;
    background: var(--card-color);
    border-radius: 5px;
    padding: 20px;
    justify-content: space-between;
    flex-direction: column;
    text-align: left;
    margin-bottom: 10px;
}

.objectives-editor article .read-only {
    display: flex;
    justify-content: space-between;
}

.objectives-editor article textarea {
    width: 96%;
    min-height: 200px;
    flex-grow: 1;
    resize: none;
    border-radius: 5px;
    padding: 2%;
    border: none;
}

.objectives-editor .buttons {
    display: flex;
    justify-content: space-between;
}

.objectives-editor .buttons button {
    flex-grow: 1;
    background-color: var(--objectives-color);
    color: var(--objectives-contrast-color);
}

.objective-text {
    background: var(--card-color);
    padding: 20px;
    border-radius: 5px;
    text-align: left;
    margin-bottom: 10px;
}

.objective-list button {
    margin-top: 10px;
    background-color: var(--objectives-color);
    color: var(--objectives-contrast-color);
    width: 100%;
}

.objectives-editor .buttons button.delete, .objectives-editor .buttons button.completed-objective {
    margin-right: 10px;
    background-color: var(--cancel-color);
    flex-grow: 0.25;
}

.objectives-editor .buttons button.completed-objective {
    background-color: var(--good-color);
    color: black;
}