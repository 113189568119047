.dashboard {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: calc(100% - 67px);
    padding-top: 50px;
}

.dashboard .left {
    display: flex;
    flex-direction: column;
}

.data-buttons {
    margin-top: -42px;
}

.data-buttons h4 {
    margin-top: 0;
}

.data-buttons input {
    width: 46%;
}

.button-margin-bottom {
    margin-bottom: 10px;
}

.date-buttons {
    display: flex;
    justify-content: space-between;
}

.react-datepicker__month-select, .react-datepicker__year-select {
    background-color: transparent;
    padding: 5px;
}

.react-datepicker-wrapper, .react-datepicker__input-container {
    width: 100%;
}

.react-datepicker__input-container input {
    display: flex;
    flex-direction: column;
    margin-right: 0 !important;
    width: 90px;
    cursor: pointer;
}

.date-buttons .spacer {
    width: 10px;
}

.filters, .data-buttons {
    display: flex;
    flex-direction: column;
}

.filters button, .data-buttons button {
    margin-bottom: 10px;
}



.filters p {
    margin-bottom: 0px;
}



.advanced-search {
    position: relative;
    display: flex;
    margin-bottom: 10px;
}

.advanced-search input {
    flex-grow: 1;
}
  
.advanced-search .tooltiptext {
    display: none;
    width: 400px;
    background-color: var(--main-font-color);
    color: var(--main-bg-color);
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    z-index: 1;
    left: calc(100% + 10px);
    bottom: 0px;
}
  
.advanced-search:hover .tooltiptext {
    display: flex;
}

.apprasial-type-filters {
    display: flex;
    padding: 10px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: var(--card-color);
    border-radius: 5px;
}

.apprasial-type-filters label {
    width: 95%;
    display: flex;
    justify-content: space-between;
    border-left: 5px solid;
    padding-left: 10px;
    margin: 5px 0;
}

.apprasial-type-filters label input {
    cursor: default;
}

@media(max-width: 1200px) {
    .dashboard {
        flex-direction: column;
        height: auto;
    }

    .dashboard .left {
        height: auto;
    }

    .tasks {
        margin-top: 20px;
        width: 100%;
        overflow: none;
    }
}