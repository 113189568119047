.person-card {
    background: var(--card-color);
    border-radius: 5px;
    padding: 10px;
    width: 150px;
    flex-grow: 1;
    cursor: pointer;
    text-decoration: none;
    color: var(--main-font-color);
}

.person-card img {
    border-radius: 100%;
    width: 50px;
    height: 50px;
}

.people {
    display: flex;
    flex-wrap: wrap;
    gap:10px;
    margin-top:10px;
}

.admin-buttons {
    display: flex;
    width: 100%;
    flex-direction: column;
}

.admin-buttons .button-style, .admin-buttons button {
    margin: 0 0 10px;
}

.button-and-dropdown button {
    flex-grow: 1;
    margin: auto;
    margin-right: 10px;
}